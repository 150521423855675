@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

@import '/node_modules/bootstrap/scss/bootstrap';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.text-timnak {
  color: #521231 !important;
}

.btn-timnak {
  background-color: #521231;
  color: white;
  border: none;

  &:hover {
    background-color: #521231;
    color: white;
  }
}

.hr-text2 {
  font-size: 24px;
  text-align: center;
  background: transparent;
  color: white;
  font-family: Futura, sans-serif;
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  height: 1.5em;
  opacity: 0.8;

  &:before {
    content: '';
    background: linear-gradient(to right, transparent, white, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: white;
    padding: 0 0.5em;
    line-height: 1.5em;
    background-color: white;
  }
}

.hr-text {
  font-size: 30px;
  text-align: center;
  background: transparent;
  color: #521231;
  font-family: Futura, sans-serif;
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  height: 1.5em;
  opacity: 0.8;

  &:before {
    content: '';
    background: linear-gradient(to right, transparent, #5b1b28, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: #5b1b28;
    padding: 0 0.5em;
    line-height: 1.5em;
    background-color: #f2f3fa;
  }
}

.textShadow {
  color: white !important;
  text-shadow: 1px 1px black, -1px 1px black, 1px -1px black, -1px -1px black, 1px 1px 5px black !important;
}

.seorge {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

body {
  background-color: #f2f3fa;

  //================ Layout
  .layout-header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    background-color: #fff;
    transition: all 0.4s;

    .inquire-section {
      a {
        text-decoration: none;
        color: black;
      }
    }

    .header-menu {
      .navbar-brand {
        width: 200px;

        &.is-scrolled {
          width: 200px;
        }
      }

      .dropdown-menu.show {
        width: 200px;

        @include media-breakpoint-down(lg) {
          width: 100%;
        }
      }

      .navbar-collapse {
        ul {
          li {
            position: relative;
            a {
              &.nav-link {
                color: #521231;
              }
            }
          }
        }

        .nav-item {
          &.dropdown {
            .dropdown-toggle {
              color: #521231;
            }
            .dropdown-menu {
              background-color: rgba(255, 255, 255, 0.85);
              a {
                text-decoration: none;
                color: #521232;
                white-space: normal;
              }
            }
          }
        }
      }
    }
  }

  footer {
    background: rgb(82, 18, 49);
    color: white;

    .accordion {
      .accordion-header {
        &:focus {
          border: none;
          box-shadow: none;
        }

        button {
          background-color: transparent;
          color: white;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:focus {
            border: none;
            box-shadow: none;
          }

          &::after {
            display: none;
          }
        }
      }

      .accordion-body {
        a {
          color: white;
        }
      }
    }
  }

  //=============== Home-Page
  .home-page {
    .sec-4 {
      &.group-tours {
        .group-tour-item {
          .banner {
            position: relative;

            .overlay {
              background-color: rgba(0, 0, 0, 0.7);
              max-width: 35%;
              border-radius: 10px;
              padding: 10px 20px;
              color: white;
              zoom: 0.9;
              position: relative;
              z-index: 2;

              .duration-title {
                flex-flow: row;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                .duration {
                  display: flex;
                  align-items: center;
                  margin-right: 15px;
                  span {
                    font-size: 40px;
                  }
                  sup {
                    font-size: 20px;
                  }
                }
                .title {
                  width: 100%;
                  text-align: center;
                  font-size: 20px;
                  font-weight: 700;
                }
              }
              .description {
                margin-bottom: 10px;
                p {
                  text-align: left;
                  font-size: 14px;
                  font-weight: 500;
                  margin-bottom: 0;
                }
              }
              .size-price-link {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 20px;
                margin-bottom: 10px;
                .size-price {
                  display: flex;
                  align-items: flex-end;
                  width: 100%;
                  gap: 15px;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  .size {
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    justify-content: center;
                    margin-right: 40px;
                    span {
                      font-size: 14px;
                      font-weight: 600;
                    }
                    b {
                      font-size: 30px;
                      margin: 0 auto;
                    }
                  }
                  .price {
                    display: flex;
                    align-items: center;
                    p {
                      padding-top: 0;
                      display: block;
                      font-size: 18px;
                      &:nth-child(2) {
                        margin-bottom: 0;
                      }
                    }
                    b {
                      font-size: 30px;
                      margin-right: 20px;
                    }
                  }
                }
                .link {
                  a {
                    border: 2px solid white;
                    padding: 10px 20px;
                    color: white;
                    text-decoration: none;
                    font-size: 16px;
                    &:hover {
                      text-decoration: none;
                    }
                  }
                }
              }

              @include media-breakpoint-down(md) {
                & {
                  max-width: 100%;
                }

                & {
                  .duration-title {
                    flex-flow: column;
                  }
                }
              }
            }
          }
          .cities {
            text-align: center;
            padding: 15px 0 30px 0;
            ul {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              li {
                font-size: 24px;
                color: #521232;
                margin-bottom: 15px;
                font-weight: 500;
                &:not(:last-child)::after {
                  content: '';
                  width: 40px;
                  height: 2px;
                  background-color: gray;
                  display: inline-block;
                  position: relative;
                  top: -3px;
                  margin: 0 10px;
                }
              }
            }
          }
        }
      }
    }
    .sec-8 {
      a {
        width: 100%;
        height: 100%;

        .card {
          height: 100%;
          border-radius: 12px;

          img {
            border-radius: 12px;
            object-fit: cover;

            @include media-breakpoint-down(md) {
              min-height: 250px;
              max-height: 250px;
            }
          }

          .card-body {
            z-index: 1;
            opacity: 0;
            transition: all 0.3s;
            background-color: rgba(0, 0, 0, 0.4);
            color: white;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            border-radius: 12px;

            &:hover {
              opacity: 1;
            }

            @include media-breakpoint-down(md) {
              opacity: 1;
            }

            .card-title {
              border-bottom: 3px solid white;
              padding-bottom: 10px;
              margin-bottom: 1rem;
            }

            .card-text {
              font-size: 14px;
              font-weight: 500;

              a {
                color: whitesmoke;
              }
            }
          }
        }
      }
    }
    .sec-9 {
      lite-youtube {
        max-width: 100%;
      }
    }
    .sec-11 {
      .count_div {
        padding: 40px 320px;
      }

      .count_div_mobile {
        padding: 30px 60px;
      }

      .count_number {
        color: #521231;
        font-size: 60px;
      }

      .count_number,
      .count_title {
        font-family: Montserrat, sans-serif;
        margin-top: 20px;
        text-align: center !important;
      }

      .count_title {
        color: #303030;
      }
    }
    .sec-12 {
      .subscription-page {
        position: relative;
      }

      .subscription-page img {
        border-radius: 10px;
      }

      .subscription-page .overlay {
        background: linear-gradient(90deg, transparent 0, rgba(0, 0, 0, 0.65));
        bottom: 0;
        color: #fff;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000",endColorstr="#a6000000",GradientType=1);
        padding: 2rem;
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
      }

      .subscription-page .overlay h1 {
        color: #fff;
      }

      .subscription-page .overlay button {
        background: linear-gradient(90deg, orange 50%, #fff 0);
        background-position: 100% 100%;
        background-size: 200% 100%;
        border: none;
        border-radius: 5px;
        padding: 10px;
        transition: all 0.5s ease-out;
        width: 100%;
      }

      .subscription-page .overlay button:hover {
        background-position: 0 100%;
      }

      @media only screen and (max-width: 767px) {
        .subscription-page .overlay {
          background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.65));
          width: 100%;
        }

        .subscription-page img {
          object-fit: cover;
        }
      }
    }
  }

  // ============== Tours page
  .tours-page {
    .rc-slider-mark-text {
      color: black;
    }
  }

  // ============== About-us page
  .aboutUs_title {
    color: #521231;
    font-size: 34px;
    line-height: 1;
    padding: 20px;
  }

  .aboutUs_description {
    color: #444;
    font-size: 20px;
    line-height: 1.6;
    padding: 20px;
  }

  .guide_name {
    color: #6b6b6b;
    font-size: 24px;
  }

  .guide_job,
  .guide_name {
    text-align: center !important;
  }

  .guide_job {
    color: #3b3a3a;
    font-size: 18px;
  }
}

.tourCardComponent {
  .card-title {
    h4 {
      overflow-wrap: anywhere;
    }
  }
}
